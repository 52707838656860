<!DOCTYPE html>
<html>
    <head>
        <title>Problema</title>
        <meta charset="UTF-8">
        <link rel="StyleSheet" href="app.component.css" type="text/css">
    </head>
    <body>
        <div class="all_page">
            <div class="rectangle_but">
                    <div>
                        <object data="../assets/public/logo.svg" width="120.56px"></object>
                    </div>
                    <div class="img_c">
                        <img class="elip" src="../assets/public/elipse.svg" width="247px">
                        <img class="fold" src="../assets/public/folder.svg" width="270px">
                    </div>
                    <div class="firstext">
                        <p><b>Estamos descargando el archivo adjunto de tu factura.</b></p>
                    </div>
                    <div class="secondtext">
                        <p>Si no ves ninguna descarga, <a [href]="url" (click)="refresh()">haz clic aquí </a>para realizarla manualmente.</p>
                    </div>
                    <div class="thirdtext">
                        <p>Esta factura ha sido creada y emitida por <a href="https://www.qenta.app" target="_blank">Qenta.</a> </p>
                    </div>
                    <div class="fourtext">
                        <p>Para mayor información ingresa a www.qenta.app </p>
                    </div>
            </div>
        </div>
    </body>
</html>