import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

let attach = "";
var basic = 'Basic ' + btoa('qenta:Q3nt4T35t');
var bearer = 'Bearer '
//var url_login = 'http://portaltest.qentaapp.com:5000/login';
//var url_s3 = 'http://portaltest.qentaapp.com:5000/attach';
var url_login = 'https://attachedapi.qentaapp.com/login';
var url_s3 = 'https://attachedapi.qentaapp.com/attach';
var token = '';
var ext_attach = "";
var name_attach = "";
let name_key_attach = urlParams.get('s3name');

/* URL DE PRUEBA */
// http://portaltest.qentaapp.com:4200/?s3name=test-1234

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  title = 'landing_attach';
  public pdfSource:any;

  url = "?s3name=" + (name_key_attach == null ? "" : name_key_attach)

  constructor(private http: HttpClient) {}

  async ngOnInit() {

    if(name_key_attach != null){
      await this.http.request('POST', url_login, {headers: {'Authorization': basic}}).subscribe((res) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          token = resJSON.access_token;

          this.http.request('GET', url_s3, { headers: {'Authorization': bearer + token}, params:{"key": name_key_attach == null ? "" : name_key_attach} }).subscribe((res) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            ext_attach = resJSON.ext;
            name_attach = resJSON.name;
            attach = resJSON.body

            this.downloadATTACH();
          });
      });
    }
  }

  public downloadATTACH() 
  {
    if(name_key_attach != null){
      const linkSource = `data:application/octet-stream;base64,${attach}`;
      const downloadLink = document.createElement("a");
      const fileName = name_attach.toString() + "." + ext_attach.toString();
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

  public refresh() 
  {
    console.log("refresh")
  }
}
